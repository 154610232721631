/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const marketplaceCustomFilters = {
  pressure_type: {
    key: 'pressure_type',
    label: 'Pressure Type',
    type: 'PressureTypeFilter',
    group: 'secondary',
    schemaType: 'pressure_type',
    queryParamNames: ['pub_pressure_type'],
    min: 0,
    max: 300,
    step: 5,
  },
  horsepower: {
    key: 'horsepower',
    label: 'Horsepower',
    type: 'NumberScaleFilter',
    group: 'secondary',
    schemaType: 'horsepower',
    queryParamNames: ['pub_horsepower'],
    min: 0,
    max: 1500,
    max: 1500,
    step: 6,
  }
};